import React, { useEffect, useState } from 'react';
import {
  Flex,
  Spacer,
  Alert,
  ChakraProvider,
  Text,
  Box,
  Center,
  Button,
  Stack,
  theme,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CircularProgress,
  Link

} from '@chakra-ui/react';
import { DownloadIcon, RepeatIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { saveAs } from "file-saver";

function App() {

  const [face, setFace] = useState('');
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState('');

  const download = async () => {
    let url = face
    saveAs(url);
  }

  const getFace = async () => {
    try {
      const res = await fetch(
        'https://qx42ph2rnsk3o3zfibucojqqca0cvure.lambda-url.us-east-1.on.aws'
      );
      const data = await res.json();
      setFace(data.urlImage);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const getFaceLoaded = async () => {
    setLoaded(true)
    await getFace()
    setLoaded(false)
  };

  useEffect(() => {
   
    const getFaceInitial = async () => {
      setLoading(true)
      await getFace()
      setLoading(false)
    };
    getFaceInitial();
  }, []);

  return (
    <ChakraProvider theme={theme}>

      {loading ? (
        <Flex
          minH={'75vh'}
          fontSize={'2xl'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CircularProgress isIndeterminate color='orange' />
        </Flex>
      ) : error ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{error.title}</AlertTitle>
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      ) : (
        <>
          <Box
            w='100%'
            h='100%'
            minHeight="100vh"
            bgGradient='linear(to-b, gray.300, orange.400, orange.200)'
          >

            <Box
              display='flex'
              alignItems='center'
              width='100%'
              minHeight="100vh"
              bgImage={face}
              bgPosition='center'
              bgRepeat='no-repeat'
              verticalAlign='center'
            >

              <Center w="100%">
                <Flex direction="column" minHeight="90vh">
                <Spacer />
                  <Box>
                    <Stack spacing={3}>
                      <Center w="100%">
                        <Text fontSize='md' color="white">Esta pessoa não existe.</Text>
                      </Center>
                      <Center w="100%">
                      <Stack direction='row' spacing={4} align="center">
                        <Button isLoading={loaded} loadingText='Gerando...' onClick={getFaceLoaded} rightIcon={<RepeatIcon />} colorScheme='orange' width='200px' >Criar nova face</Button>
                        <Button rightIcon={<DownloadIcon />} onClick={download} colorScheme='orange'>Baixar</Button>
                      </Stack>
                      </Center>
                      
                    </Stack>
                  </Box>
                  <Spacer />
                  <Stack spacing={5}>
                    <Center>
                      <Link color="white" href='https://github.com/rosinality/style-based-gan-pytorch' isExternal>
                        Baseado nos scripts de geração e pesos treinados de Kim Seonghyeon. <ExternalLinkIcon mx='2px' />
                      </Link>
                    </Center>
                  </Stack>
                </Flex>
              </Center>




            </Box>


          </Box>

        </>
      )}
    </ChakraProvider>
  );
}

export default App;
